<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-10 17:37:03
-->
<template>
  <div class="container-warp">
    <!-- 头部筛选--开始 -->
      <el-card class="card-pd" shadow='never'>
        <div slot="header">
          <el-tabs v-model="activeName"  @tab-click="_tabClick" :style="{'--font-Color' : fontColor}">
            <el-tab-pane :label="`未开始（${notStartNum}）`" name="0"></el-tab-pane>
            <el-tab-pane :label="`进行中（${pendingNum}）`" name="1"></el-tab-pane>
            <el-tab-pane :label="`已结束（${endNum}）`" name="2"></el-tab-pane>
        </el-tabs>
          <el-form  class="form-item-no-mb" :model="searchForm"  ref="searchForm" inline>
            <el-form-item label="考试名称">
              <el-input v-model="searchForm.examName" placeholder="请输入考试名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="_reset" style="margin-left:0px;">重置</el-button>
              <el-button type="primary" style="margin-left:16px;" @click="_getExamArrangeList('search')">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="arrange-exam">
          <el-button type="primary" @click="_arrangeExamBtn()">安排考试</el-button>
        </div>
      </el-card>
      <!-- 头部筛选---结束 -->
      <!-- 表格区域---开始 -->
      <div class="table-box">
        <el-table :data="examListData" height="530" :key="refreshTableKey">
          <el-table-column
            v-for="(item, index) in tableColumns"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            :show-overflow-tooltip="item.overflow">
            <template slot-scope="scope">
              <span v-if="item.prop=='startTime'">
                {{ scope.row.startTime?`${scope.row.startTime}至${scope.row.endTime}`:'—' }}
              </span>
              <span v-else-if="item.prop=='passRate'">
              <!-- 通过率 -->
                {{ scope.row.passRate?`${scope.row.passRate}%`:'0%' }}
              </span>
              <span v-else>
                {{ scope.row[item.prop]?scope.row[item.prop]:'—' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" :width="activeName=='0'?'220':(activeName=='1'?'130':(activeName=='2'?'130':''))">
            <template slot-scope="scope">
                <el-button v-if="activeName==='0'" type="text" class="text-btn" @click="_editExamBtn(scope.row)">编辑</el-button>
                <el-button v-if="activeName==='0'" type="text" class="text-btn" @click="_immdiateExamBtn(scope.row)">立即开考</el-button>
                <el-button type="text" class="text-btn" @click="_detailExamBtn(scope.row)">详情</el-button>
                <el-button v-if="activeName=='0'||activeName=='2'" type="text" class="text-btn" @click="_deleteExamBtn(scope.row)">删除</el-button>
                <el-button v-if="activeName==='1'" type="text" class="text-btn" @click="_closeExamBtn(scope.row)">结束考试</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 试卷分页---开始 -->
        <el-pagination
          class="fx-row-end pt16"
          @size-change="_handleSizeChange"
          @current-change="_handleCurrentChange"
          :current-page="searchForm.page"
          :page-sizes="[5, 10, 20, 30, 40]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="paperTotal">
        </el-pagination>
        <!-- 试卷分页---结束 -->
      </div>
      <!-- 表格区域---结束 -->
      <!-- 编辑考试-弹窗--开始 -->
      <el-dialog class="exam-dialog" title="编辑" :close-on-click-modal="false" :visible.sync="editExamArrangeDialog">
        <el-form :model="examArrangeForm" label-width="86px" :rules="examArrangeRule" ref="examArrangeForm">
          <el-form-item label="考试名称" prop="examName">
            <el-input v-model="examArrangeForm.examName" placeholder="请输入考试名称"></el-input>
          </el-form-item>
          <el-form-item label="考试时间" prop="startTime">
            <el-date-picker
              style="width: 100%;"
              v-model="examTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :default-time="['00:00:00', '23:59:59']"
              @change="_selectExamTime">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editExamArrangeDialog = false">取 消</el-button>
          <el-button type="primary" @click="_editExamConfirm">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 编辑考试-弹窗--结束 -->
  </div>
</template>

<script>
import { getExamArrangeListApi, getArrangeStatusNumApi, getExamArrangeEditApi, getImmediateExamApi, getExamArrangeDeleteApi, getFinishExamApi } from '@/api/examApi'
import deleteFinalData from '@/mixins/deleteFinalData'
import { getCurrentTime } from '@/utils/util'
export default {
  mixins: [deleteFinalData],
  components: { },
  data () {
    return {
      fontColor: this.$store.state.settings.theme,
      refreshTableKey: new Date().getTime(),
      paperId: '', // 试卷id
      searchForm: {
        examName: '', // 考试名称
        examState: null,
        page: 1,
        pageSize: 10
      },
      // 表格数据
      examListData: [],
      // 表格字段
      tableColumns: [],
      defaultColumns: [
        { prop: 'examName', label: '考试名称', overflow: true },
        { prop: 'startTime', label: '考试时间', width: 280 },
        { prop: 'testPaperName', label: '关联试卷名称', overflow: true },
        { prop: 'studyPlanName', label: '关联学习计划', overflow: true },
        { prop: 'testerNum', label: '应考人数' }
      ],
      pendingColumns: [
        { prop: 'examName', label: '考试名称', width: '150px', overflow: true },
        { prop: 'startTime', label: '考试时间', width: 280 },
        { prop: 'testPaperName', label: '关联试卷名称', overflow: true },
        { prop: 'studyPlanName', label: '关联学习计划', overflow: true },
        { prop: 'testerNum', label: '应考人数' },
        { prop: 'actualExamNum', label: '实考人数', width: '150px', overflow: true },
        { prop: 'notExamNum', label: '未考人数', overflow: true },
        { prop: 'waitCorrectedNum', label: '待批阅人数', overflow: true }
      ],
      endColumns: [
        { prop: 'examName', label: '考试名称', width: '150px', overflow: true },
        { prop: 'startTime', label: '考试时间', width: 280 },
        { prop: 'testPaperName', label: '关联试卷名称', overflow: true },
        { prop: 'studyPlanName', label: '关联学习计划', overflow: true },
        { prop: 'testerNum', label: '应考人数' },
        { prop: 'actualExamNum', label: '实考人数', width: '150px', overflow: true },
        { prop: 'notExamNum', label: '未考人数', overflow: true },
        { prop: 'passRate', label: '通过率', overflow: true }
      ],
      activeName: '0',
      editExamArrangeDialog: false,
      examArrangeForm: { // 编辑考试安排
        examName: '', // 考试名称
        startTime: '',
        endTime: ''
      },
      examTime: '', // 考试时间
      paperTotal: 0,
      notStartNum: 0,
      pendingNum: 0,
      endNum: 0,
      examArrangeRule: {
        examName: [
          { required: true, message: '请输入考试名称', trigger: ['change', 'blur'] }
        ],
        startTime: [
          { required: true, message: '请选择', trigger: ['change', 'blur'] }
        ]
      },
      durationTime: 0
    }
  },
  created () {
    this._getExamArrangeList()
  },
  mounted () {
    this.fontColor = this.$store.state.settings.theme
    this.tableColumns = [...this.defaultColumns]
  },

  methods: {
    // 选择考试时间
    _selectExamTime (a) {
      if (a) {
        const examStartTime = new Date(a[0]).getTime()// 考试设置的开始时间
        const examEndTime = new Date(a[1]).getTime()// 考试设置的截止时间
        const currentTime = new Date(getCurrentTime()).getTime()// 当前时间
        if (examStartTime < currentTime || examEndTime < currentTime) {
          this.examTime = ''
          this.examArrangeForm.startTime = ''
          this.examArrangeForm.endTime = ''
          return this.$message.error('考试开始和结束时间不能小于当前时间！')
        }
        this.examArrangeForm.startTime = a[0]
        this.examArrangeForm.endTime = a[1]
      } else {
        this.examArrangeForm.startTime = ''
        this.examArrangeForm.endTime = ''
      }
    },
    // 获取考试安排列表
    _getExamArrangeList (type) {
      if (type) {
        this.searchForm.page = 1
        this.searchForm.pageSize = 10
      }
      this.refreshTableKey = new Date().getTime()
      this.searchForm.examState = this.activeName
      getExamArrangeListApi({ ...this.searchForm }).then((res) => {
        if (res.code === 200) {
          this.examListData = res.object.results
          this.paperTotal = res.object.total
          this._getArrangeStatusNum()
        }
      })
    },
    // 获取考试安排各个状态数量
    _getArrangeStatusNum () {
      getArrangeStatusNumApi().then((res) => {
        if (res.code === 200 && res.data) {
          this.notStartNum = res.data.notStartedNum
          this.pendingNum = res.data.progressNum
          this.endNum = res.data.endedNum
        }
      })
    },
    // 分页
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this._getExamArrangeList()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this._getExamArrangeList()
    },
    // 重置
    _reset () {
      this.searchForm.examName = ''
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this._getExamArrangeList()
    },
    // 切换tab
    _tabClick (tab) {
      if (this.activeName === '0') {
        this.tableColumns = [...this.defaultColumns]
      }
      if (this.activeName === '1') {
        this.tableColumns = [...this.pendingColumns]
      }
      if (this.activeName === '2') {
        this.tableColumns = [...this.endColumns]
      }
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this._getExamArrangeList()
    },
    // 安排考试
    _arrangeExamBtn () {
      // console.log('安排考试')
      this.$router.push({
        path: '/addExamArrange'
      })
    },
    // 操作按钮---编辑
    _editExamBtn (row) {
      this.editExamArrangeDialog = true
      this.durationTime = row.duration
      this.examArrangeForm.id = row.examInfoId
      this.examArrangeForm.examName = row.examName
      this.examArrangeForm.startTime = row.startTime
      this.examArrangeForm.endTime = row.endTime
      this.examTime = [row.startTime, row.endTime]
    },
    // 操作按钮---立即开考
    _immdiateExamBtn (row) {
      getImmediateExamApi({ id: row.examInfoId }).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功！')
          this._getExamArrangeList()
        }
      })
    },
    // 操作按钮---删除
    _deleteExamBtn (row) {
      // 二次确认删除
      this.$confirm('确定要删除吗？', '提示', { type: 'warning' })
        .then(() => {
          getExamArrangeDeleteApi({ id: row.examInfoId }).then((res) => {
            if (res.code === 200) {
              this.$message.success('删除成功！')
              this.searchForm.page = this._deleteFinalData(this.paperTotal - 1, this.searchForm.pageSize, this.searchForm.page)
              this._getExamArrangeList()
            }
          })
        }).catch(() => {})
    },
    // 操作按钮---详情
    _detailExamBtn (row) {
      console.log(row, 'row')
      this.$router.push({
        path: '/examArrangeDetail',
        query: {
          examId: row.examInfoId
        }
      })
    },
    // 操作按钮---结束考试
    _closeExamBtn (row) {
      getFinishExamApi({ id: row.examInfoId }).then((res) => {
        if (res.code === 200) {
          this.editExamArrangeDialog = false
          this.$message.success('操作成功！')
          this._getExamArrangeList()
        }
      })
    },
    // 编辑考试--确认
    _editExamConfirm () {
      this.$refs.examArrangeForm.validate((valid) => {
        if (valid) {
          console.log(this.examArrangeForm, this.durationTime)
          if (this.durationTime) {
            const examStartTime = new Date(this.examArrangeForm.startTime).getTime()// 考试设置的开始时间
            const examEndTime = new Date(this.examArrangeForm.endTime).getTime()// 考试设置的截止时间
            const differentTime = examEndTime - examStartTime
            const durationTime = Number(this.durationTime) * 60 * 1000
            if (differentTime < durationTime) {
              this.examTime = ''
              this.examArrangeForm.startTime = ''
              this.examArrangeForm.endTime = ''
              return this.$message.error('考试时间设置的区间不可小于考试时长！')
            }
          }
          getExamArrangeEditApi(this.examArrangeForm).then((res) => {
            if (res.code === 200) {
              this.editExamArrangeDialog = false
              this.$message.success('编辑成功！')
              this._getExamArrangeList()
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../style.scss";
/* tabs样式 */
/deep/.el-tabs__item.is-active{
  color:var(--font-Color)!important;
}
/deep/.el-tabs__item{
  color: #737373!important;
  padding: 0 16px!important;
}
.card-pd{
  /deep/.el-card__header{
    padding: 6px 24px 16px 24px;
  }
  /deep/ .el-card__body{
    padding: 16px 24px;
  }
}
  .form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 16px;
    margin-right: 32px;
  }
  /deep/.el-form-item__label{
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.90);
  }
}
.arrange-exam{
  /deep/.el-button{
    margin-left: 0px !important;
  }
}
/deep/.el-button--default{
  color:  rgba(0, 0, 0, 0.90);
}
.exam-dialog{
  /deep/.el-dialog{
    width: 500px;
  }
  /deep/.el-dialog__title{
    font-size: 16px;
    color: hsla(0, 0%, 0%, 0.9);
    font-weight: 500;
  }
  /deep/.el-dialog__body{
    padding: 20px 30px 14px 24px;
  }
}
.text-btn{
  margin:0 10px 0 0!important;
}
.table-box{
  background-color: #fff;
  padding-bottom: 16px;
}
</style>
